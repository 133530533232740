import React from 'react';
import { default as styled } from '@mui/material/styles/styled';
import { CircularProgress } from '@material-ui/core';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@cherre-frontend/ui';

const DialogStyled = styled(Dialog)`
  & .MuiPaper-root {
    width: 520px;
    padding: 20px;
  }
  & .MuiTypography-root {
    padding: 0;
    font-size: 20px;
    margin-bottom: 30px;
    font-weight: 600px;
  }
  & .MuiDialogContent-root {
    display: flex;
    justify-content: center;
  }
`;

export type ConfirmationModalProps = {
  open: boolean;
  progress: number;
  handleClose: () => void;
};

const LoadingReportDialog: React.FC<ConfirmationModalProps> = ({
  open,
  progress,
  handleClose,
}) => {
  return (
    <DialogStyled open={open}>
      <DialogTitle>Preparing the Export File</DialogTitle>
      <DialogContent>
        <CircularProgress
          value={progress}
          variant={progress ? 'determinate' : undefined}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => handleClose()}>
          Cancel
        </Button>
      </DialogActions>
    </DialogStyled>
  );
};

export default LoadingReportDialog;
