import { styled, Paper, PageContainer } from '@cherre-frontend/ui';

export const PaperStyled = styled(Paper)`
  padding: 17px;
  width: 100%;

  .MuiInputBase-root svg {
    color: ${({ theme }) => theme.palette.grey[500]};
  }

  span.suspend {
    margin-top: 10px;
    width: 190px;
    height: 24px;
  }
`;

export const PageContainerStyled = styled(PageContainer)`
  background: ${({ theme }) => theme.palette.grey[200]};
  height: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled('div')`
  width: 650px;
`;

export const Title = styled('h1')`
  margin-top: 24px;
  margin-right: 15px;
  font-weight: normal;
  color: ${({ theme }) => theme.palette.grey[900]};
  font-size: 36px;
`;

export const TitleHolder = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;

  svg {
    color: black;
  }
`;

export const Info = styled('p')`
  align-self: center;
  width: 100%;
  text-align: center;
`;
