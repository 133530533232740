import { PowerBIReport } from './types';
import { groupBy } from 'lodash';

export const sortPowerBIReports = (
  reports: PowerBIReport[],
  defaultReportId?: string
): PowerBIReport[] => {
  const defaultReport = reports.find((r) => r.id === defaultReportId);

  const groupedByEnvironment = groupBy(
    reports.filter((r) => r.id !== defaultReportId),
    (r) => r.environment?.sortIndex ?? 5
  );

  const sortedGroupedReports = Object.keys(groupedByEnvironment)
    .sort((a, b) => Number(a) - Number(b))
    .map((sortIndex) =>
      groupedByEnvironment[sortIndex].sort((a, b) =>
        a.name.localeCompare(b.name)
      )
    )
    .flat();

  return [defaultReport, ...sortedGroupedReports].filter(
    Boolean
  ) as PowerBIReport[];
};
