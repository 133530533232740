import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Paper,
  Typography,
  styled,
  MuiAutocomplete,
  TextField,
  Box,
  Tooltip,
  Chip,
} from '@cherre-frontend/ui';
import { useAppContext } from '@cherre-frontend/core';
import {
  useCherreSetState,
  useCherreValue,
  useIsSuspended,
} from '@cherre-frontend/data-fetching';
import { reportsSelector, defaultReportSelector } from '../recoil';
import { sortPowerBIReports } from '../utils';
import { PowerBIReport } from '../types';

const PaperStyled = styled(Paper)`
  margin: auto;
  margin-top: 100px;
  padding: 100px 250px;
`;

const ButtonStyled = styled(Button)`
  margin-top: 20px;
`;

export const DefaultReportSelection: React.FC = () => {
  const reportsResponse = useCherreValue(reportsSelector);
  const setDefaultReport = useCherreSetState(defaultReportSelector);
  const [value, setValue] = useState<PowerBIReport>();

  const suspended = useIsSuspended();
  const history = useHistory();

  const ctx = useAppContext();
  const reports = reportsResponse?.reports;

  const tooltipOptions = useMemo(() => {
    return sortPowerBIReports(reports ?? []);
  }, [reports]);

  const onSave = () => {
    const report = reports?.find((r) => r.id === value?.id);
    setDefaultReport(report?.id);
    ctx.showSnackbar({
      type: 'success',
      message: 'Default display report selected.',
    });
    history.push(`/reports/${report?.id}`);
  };

  return (
    <PaperStyled>
      <Typography variant='h4'>Select Default Report</Typography>
      <Typography
        maxWidth={350}
        paddingBottom={2}
        paddingTop={2}
        variant='body1'
      >
        Select the report you would like to see by default in the Dashboards
        view.
      </Typography>
      <MuiAutocomplete
        size='small'
        value={value}
        onChange={(e, newValue) => {
          setValue(newValue);
        }}
        renderOption={(props, option) => (
          <Box component='li' {...props}>
            {option?.environment?.label && (
              <Tooltip
                disableInteractive
                leaveTouchDelay={0}
                title={option.workspaceName}
              >
                <Chip
                  style={{ marginRight: 10 }}
                  variant='outlined'
                  size='small'
                  label={option.environment.label}
                />
              </Tooltip>
            )}
            <span>{option.name}</span>
          </Box>
        )}
        getOptionLabel={(option) => option.name ?? ''}
        disablePortal
        disableClearable
        disabled={suspended}
        options={tooltipOptions}
        renderInput={(params) => (
          <TextField {...params} label='Default report...' />
        )}
      />
      <ButtonStyled disabled={!value} onClick={onSave} variant='contained'>
        Save
      </ButtonStyled>
    </PaperStyled>
  );
};
