import React, { useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import {
  Divider,
  DownloadFileIcon,
  IconButton,
  Menu,
  MenuItem,
  Panel,
  TextField,
  Box,
  StarIcon,
  StarOutlineIcon,
  Tooltip,
  PDFIcon,
  PPTICon,
  useTheme,
  Chip,
} from '@cherre-frontend/ui';
import { useHistory } from 'react-router-dom';
import { useAppContext, useCherreEvent } from '@cherre-frontend/core';
import {
  useCherreState,
  useCherreValue,
  useIsSuspended,
} from '@cherre-frontend/data-fetching';
import {
  reportsSelector,
  selectedReportAtom,
  defaultReportSelector,
  localStateDefaultReport,
} from '../recoil';
import { Autocomplete, Wrapper } from './styles';
import { PowerBIReport } from '../types';
import LoadingReportDialog from './LoadingReportDialog';
import moment from 'moment';
import { sortPowerBIReports } from '../utils';

type ExportStatus =
  | 'Succeeded'
  | 'Failed'
  | 'NotStarted'
  | 'Running'
  | 'Undefined';
type ExportStatusResponse = {
  status: ExportStatus;
  progress: number;
};

const Content = () => {
  const theme = useTheme();
  const reportsResponse = useCherreValue(reportsSelector);
  const selectedReport = useCherreValue(selectedReportAtom);
  const [value, setValue] = useState<PowerBIReport>({} as PowerBIReport);

  const [progress, setProgress] = useState(0);
  const [isExporting, setIsExporting] = useState(false);
  const isExportingRef = useRef(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleExportReportClick = useCherreEvent(
    'handleExportReportClick',
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    }
  );
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const localDefaultReportId = useCherreValue(localStateDefaultReport);
  const [apiDefaultReportId, setDefaultReportId] = useCherreState(
    defaultReportSelector
  );

  const defaultReportId = localDefaultReportId ?? apiDefaultReportId;

  const suspended = useIsSuspended();
  const history = useHistory();
  const ctx = useAppContext();

  const handleExportClick = async (fileFormat: string) => {
    setIsExporting(true);
    setProgress(0);
    isExportingRef.current = true;
    handleClose();

    try {
      const { id: reportId, workspaceId } = selectedReport ?? {};

      const { data: exportData } = await axios.post<{ exportId: string }>(
        `/api/v1/powerbi/reports/export`,
        {
          fileFormat,
          workspaceId,
          reportId,
        }
      );

      let pooling = true;
      while (pooling && isExportingRef.current) {
        const { data } = await axios.get<ExportStatusResponse>(
          `/api/v1/powerbi/reports/${workspaceId}/${reportId}/${exportData.exportId}`
        );

        pooling = data.status !== 'Succeeded' && data.status !== 'Failed';

        setProgress(data.progress);

        if (pooling) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }

      if (!isExportingRef.current) {
        return;
      }

      const exportedFile = await axios.get<Blob>(
        `/api/v1/powerbi/reports/${workspaceId}/${reportId}/${exportData.exportId}/file`,
        {
          responseType: 'blob',
        }
      );

      const link = document.createElement('a');
      const url = URL.createObjectURL(exportedFile.data);

      const currentDate = moment().format('MMM DD, YYYY');

      link.setAttribute('href', url);
      link.setAttribute(
        'download',
        `${
          selectedReport?.name || 'Report'
        } (${currentDate}).${fileFormat.toLowerCase()}`
      );
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      ctx.showSnackbar({
        type: 'error',
        message: 'Error exporting report.',
      });
    } finally {
      isExportingRef.current = false;
      setIsExporting(false);
      setProgress(0);
    }
  };

  const reports: PowerBIReport[] = reportsResponse?.reports ?? [];

  useEffect(() => {
    if (selectedReport && reports) {
      setValue(selectedReport);
    }
  }, [selectedReport, reports]);

  const tooltipOptions = useMemo(() => {
    return sortPowerBIReports(reports, defaultReportId);
  }, [reports, defaultReportId]);

  const onClickStar = (id: string) => {
    setDefaultReportId(id);
    ctx.showSnackbar({
      type: 'success',
      message: 'Default display report selected.',
    });
  };

  return (
    <Wrapper suspended={suspended}>
      <Autocomplete
        size='small'
        value={value}
        onChange={(e, report) => {
          setValue(report);
          history.push(report?.id ? `/reports/${report?.id}` : '/reports');
        }}
        renderOption={(props, option) => (
          <Box component='li' {...props}>
            {option?.environment?.label && (
              <Tooltip
                disableInteractive
                leaveTouchDelay={0}
                title={option.workspaceName}
              >
                <Chip
                  variant='outlined'
                  size='small'
                  label={option.environment.label}
                />
              </Tooltip>
            )}
            <span
              style={{
                flex: 'auto',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {option.name}
            </span>
            {defaultReportId === option.id ? (
              <Tooltip
                disableInteractive
                leaveTouchDelay={0}
                title='Default Dashboard'
              >
                <StarIcon />
              </Tooltip>
            ) : (
              <Tooltip
                disableInteractive
                leaveTouchDelay={0}
                title='Make Default'
              >
                <StarOutlineIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickStar(option.id);
                  }}
                  className={'starOutline'}
                />
              </Tooltip>
            )}
          </Box>
        )}
        getOptionLabel={(option) => option.name ?? ''}
        disablePortal
        disableClearable
        disabled={suspended}
        options={tooltipOptions}
        renderInput={(params) => (
          <TextField {...params} label='Select Dashboard' />
        )}
      />
      <Tooltip title='Download Dashboard'>
        <IconButton
          size='small'
          color='primary'
          onClick={handleExportReportClick}
          data-testid='download-report-button'
          disabled={!selectedReport || isExporting}
        >
          <DownloadFileIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        data-testid='export-report-menu'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem key='PDF' onClick={() => handleExportClick('PDF')}>
          <PDFIcon color='primary' /> PDF
        </MenuItem>
        <MenuItem key='PPTX' onClick={() => handleExportClick('PPTX')}>
          <PPTICon color='primary' /> PowerPoint
        </MenuItem>
      </Menu>
      <LoadingReportDialog
        open={isExporting}
        progress={progress}
        handleClose={() => {
          isExportingRef.current = false;
        }}
      />
      <Divider
        style={{
          borderWidth: '1px',
          height: '100%',
          background: theme.palette.primary.light,
        }}
      />
    </Wrapper>
  );
};

export const SelectReport: React.FC = () => {
  return (
    <Panel id='SelectReport'>
      <Content />
    </Panel>
  );
};
