import React, { useEffect, useState, useCallback } from 'react';
import {
  useCherreValue,
  DataFetchingError,
  RecoilState,
  FetchError,
  useIsSuspended,
} from '@cherre-frontend/data-fetching';
import { AxiosResponse } from 'axios';
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  IconButton,
  Button,
  TextField,
  SearchIcon,
  Typography,
} from '@cherre-frontend/ui';
import { isEqual, escapeRegExp } from 'lodash';
import {
  PowerBIWorkspace,
  WorkspaceAccessReponse,
} from 'src/products/core-bi-v2/types';
import {
  PaperStyled,
  PageContainerStyled,
  Title,
  TitleHolder,
  Content,
  Info,
} from './styles';

import ArrowBack from '@material-ui/icons/ArrowBack';
import { DefaultErrorBoundary } from 'src/products/shell/DataFetchingConfig';
import { usePermission } from 'src/hooks';

type Props = {
  title: string;
  workspaceAccess: RecoilState<WorkspaceAccessReponse>;
  update: (formState: FormState) => void;
  backClick: () => void;
};

type FormState = {
  [workspaceId: string]: boolean;
};

const WorkspacesAccessPage = ({
  title,
  update,
  backClick,
  workspaceAccess,
}: Props) => {
  const isAllowedToSeeAllWorkspaces = usePermission([
    'CherreAdmin',
    'PowerBIAdmin',
  ]);

  const [baseState, setBaseState] = useState<FormState>();
  const [formState, setFormState] = useState<FormState>();

  const [searchText, setSearchText] = useState('');

  const suspended = useIsSuspended();

  const filterWorkspace = useCallback(
    ({ workspace }: { workspace: PowerBIWorkspace }) => {
      const pattern = new RegExp(escapeRegExp(searchText), 'gi');
      return !searchText || !!workspace.name?.match(pattern);
    },
    [searchText]
  );

  const response = useCherreValue<WorkspaceAccessReponse>(workspaceAccess);

  useEffect(() => {
    if (!response) {
      return;
    }

    const initialState = response?.workspaceAccess.reduce(
      (acc, v) => ({
        ...acc,
        [v.workspace.id]: v.hasAccess,
      }),
      {}
    );

    setBaseState(initialState);
    setFormState(initialState);
  }, [response]);

  const notChanged = isEqual(formState, baseState);

  const workspaceAccessFiltered = response?.workspaceAccess.filter(
    (workspaceAccess) => {
      const envName = workspaceAccess.workspace.environment?.name;
      if (envName === 'Development' || envName === 'PTY') {
        return isAllowedToSeeAllWorkspaces;
      }

      return true;
    }
  );

  return (
    <Content>
      <TitleHolder>
        <IconButton onClick={backClick}>
          <ArrowBack />
        </IconButton>
        <Title>{title}</Title>
      </TitleHolder>
      <PaperStyled>
        {!suspended && (workspaceAccessFiltered?.length ?? 0) >= 10 && (
          <TextField
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              endAdornment: <SearchIcon />,
            }}
            variant='outlined'
            size='small'
            placeholder='Search workspace'
          />
        )}
        <FormGroup>
          {suspended && (
            <>
              <span className='suspend' />
              <span className='suspend' />
              <span className='suspend' />
            </>
          )}
          {!suspended &&
            workspaceAccessFiltered
              ?.filter(filterWorkspace)
              .map(({ workspace }) => (
                <FormControlLabel
                  key={workspace.id}
                  label={workspace.name}
                  control={
                    <Checkbox
                      title={workspace.name}
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          [workspace.id]: e.target.checked,
                        })
                      }
                      checked={!!formState?.[workspace.id]}
                    />
                  }
                />
              ))}
        </FormGroup>
        {workspaceAccessFiltered?.length === 0 && (
          <Typography>No workspaces found</Typography>
        )}
        <Button
          variant='contained'
          type='submit'
          style={{ marginTop: 10 }}
          className='suspend'
          onClick={() => {
            if (!formState) {
              return;
            }

            update(formState);
          }}
          disabled={notChanged}
        >
          Update
        </Button>
      </PaperStyled>
    </Content>
  );
};

export const WorkspaceAccessAdmin: React.FC<Props> = (props) => {
  return (
    <PageContainerStyled
      errorFallback={(props) => {
        const error = props.error;

        if (error instanceof DataFetchingError) {
          if (error.originalError instanceof FetchError) {
            const response = error.originalError.error
              .response as AxiosResponse;
            if (
              response?.data?.errors?.some(
                (error) => error?.message === 'Missing Power BI configuration'
              )
            ) {
              return <Info>PowerBI not configured for this organization</Info>;
            }
          }
        }

        return <DefaultErrorBoundary {...props} />;
      }}
      id={'organization-workspace-access-admin'}
    >
      <WorkspacesAccessPage {...props} />
    </PageContainerStyled>
  );
};
