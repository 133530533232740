import React, { useCallback, useEffect, useMemo } from 'react';
import { EventHandler, PowerBIEmbed } from 'powerbi-client-react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { models } from 'powerbi-client';
import {
  NotFoundError,
  useCherreResetState,
  useCherreState,
  useCherreValue,
  useIsSuspended,
} from '@cherre-frontend/data-fetching';
import { useAppContext } from '@cherre-frontend/core';
import {
  selectedReportAtom,
  selectedReportToken,
  reportsSelector,
  defaultReportSelector,
  genericTokenSelector,
} from './recoil';

import { PageContainer, EmbedContainer, Info } from './styles';
import { DefaultReportSelection } from './DefaultReportSelection';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';

// 5 minutes
const TIME_BEFORE_EXPIRATION = 5 * 60 * 1000;

const IFRAME_EVENTS_TO_TRACK = [
  // Report events (https://learn.microsoft.com/en-us/javascript/api/overview/powerbi/handle-events#events-and-their-response-values)
  'buttonClicked',
  'commandTriggered',
  'dataHyperlinkClicked',
  'dataSelected',
  'loaded',
  'pageChanged',
  'rendered',
  'saveAsTriggered',
  'saved',
  'selectionChanged',
  'visualClicked',
  'visualRendered',
  // Dashboard events
  'loaded',
  'tileClicked',
];

const CoreBIV2 = ({
  match,
  location,
}: RouteComponentProps<{ report_id: string }>) => {
  const pathReportId = match?.params?.report_id;
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const [selectedReport, setSelectedReport] =
    useCherreState(selectedReportAtom);

  const suspended = useIsSuspended();
  const history = useHistory();

  const defaultReportId = useCherreValue(defaultReportSelector);

  const embedToken = useCherreValue(selectedReportToken);
  const refreshToken = useCherreResetState(genericTokenSelector);

  const reportsResponse = useCherreValue(reportsSelector);

  const debugPowerBIFeatureFlag = useFeatureFlag('PowerBIDebug');

  const generateEmbedUrl = useCallback(
    (url: string) => {
      if (!debugPowerBIFeatureFlag) {
        return url;
      }
      const u = new URL(url);
      u.searchParams.set('unmin', '1');
      return u.toString();
    },
    [debugPowerBIFeatureFlag]
  );

  const { telemetry } = useAppContext();
  const eventHandlers = useMemo(
    () =>
      new Map<string, EventHandler>(
        IFRAME_EVENTS_TO_TRACK.map((eventName) => [
          eventName,
          (event: any, embed: any) => {
            telemetry.trackEvent(`pbi-${event.type}`, {
              embedType: embed?.embedtype,
              embedId: embed?.config?.id,
              embedUrl: generateEmbedUrl(embed?.config?.embedUrl),
              groupId: embed?.config?.groupId,
            });
          },
        ])
      ),
    [telemetry]
  );

  useEffect(() => {
    if (!reportsResponse?.reports) {
      return;
    }

    if (pathReportId) {
      const searchParams = new URLSearchParams(location.search);

      if (pathReportId === selectedReport?.id) {
        return;
      }

      const reportInPath = reportsResponse.reports.find(
        (report) => report.id === pathReportId
      );

      if (!reportInPath) {
        if (searchParams.get('organizationDefaultDashboard') === 'true') {
          return history.push('/apps');
        }

        throw new NotFoundError('Report not found');
      }

      setSelectedReport(reportInPath);
      return;
    }

    if (
      defaultReportId &&
      reportsResponse.reports.some((r) => r.id === defaultReportId)
    ) {
      history.push(`/reports/${defaultReportId}`);
    }
  }, [pathReportId, reportsResponse, defaultReportId]);

  useEffect(() => {
    if (!embedToken) {
      return;
    }

    const tokenExpiration = new Date(embedToken.expiration).getTime();
    const timeout = setTimeout(() => {
      refreshToken();
    }, tokenExpiration - Date.now() - TIME_BEFORE_EXPIRATION);

    return () => {
      clearTimeout(timeout);
    };
  }, [embedToken]);

  return (
    <>
      {!suspended &&
        !selectedReport &&
        (reportsResponse?.reports?.length ? (
          <DefaultReportSelection />
        ) : (
          <Info>No reports available.</Info>
        ))}
      {suspended && <Info>Loading...</Info>}
      {selectedReport && embedToken && (
        <EmbedContainer>
          <PowerBIEmbed
            embedConfig={{
              type: 'report',
              id: selectedReport.id,
              embedUrl: generateEmbedUrl(selectedReport.embedUrl),
              accessToken: embedToken.token,
              tokenType: models.TokenType.Embed,
              filters:
                searchParams.size > 0
                  ? Array.from(searchParams.entries())
                      .filter(([key]) => key !== 'organizationDefaultDashboard')
                      .map(([key, value]) => ({
                        $schema: 'http://powerbi.com/product/schema#basic',
                        target: {
                          table: key.split('/')[0],
                          column: key.split('/')[1],
                        },
                        conditions: [
                          {
                            value,
                            operator: 'Is',
                          },
                        ],
                        logicalOperator: 'And',
                        filterType: 0,
                      }))
                  : undefined,
            }}
            eventHandlers={eventHandlers}
          />
        </EmbedContainer>
      )}
    </>
  );
};

export default withRouter((props) => (
  <PageContainer id='CoreBiV2Reports'>
    <CoreBIV2 {...props} />
  </PageContainer>
));
