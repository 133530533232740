import { atom, selector } from 'recoil';
import { getSelector } from '@cherre-frontend/data-fetching';
import { PowerBIReport } from '../types';

import * as T from '../types';

const namespace = (key: string) => `CORE-BI-V2/${key}`;

export const reportsSelector = getSelector<{ reports: PowerBIReport[] }>({
  url: `/api/v1/powerbi/reports`,
});

export const selectedReportAtom = atom<PowerBIReport | null>({
  key: namespace('SELECTED_REPORT'),
  default: null,
});

export const genericTokenSelector = getSelector<T.PowerBIToken>({
  url: `/api/v1/powerbi/embed-token`,
});

export const localStateDefaultReport = atom<string | null>({
  key: namespace('LOCAL_STATE_DEFAULT_REPORT'),
  default: null,
});

export const defaultReportSelector = selector({
  key: namespace('DEFAULT_REPORT'),
  get: ({ get }) => {
    return get(
      getSelector<T.PowerBIDefaultReport>({
        url: `/api/v1/powerbi/default-report`,
      })
    ).defaultPowerBiReportId;
  },
  set: ({ set }, reportId) => {
    if (!reportId) {
      return;
    }

    set(localStateDefaultReport, reportId);

    fetch(`/api/v1/powerbi/default-report`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        reportId,
      }),
    });
  },
});

export const selectedReportToken = selector<T.PowerBIToken | null>({
  key: namespace('SELECTED-REPORT-TOKEN'),
  get: ({ get }) => {
    const selectedReport = get(selectedReportAtom);
    const generatedToken = get(genericTokenSelector);

    if (!selectedReport) {
      return null;
    }

    if (generatedToken?.token) {
      return generatedToken;
    }

    return get(
      getSelector<T.PowerBIToken>({
        url: `/api/v1/powerbi/workspaces/${selectedReport.workspaceId}/reports/${selectedReport.id}/embed-token`,
      })
    );
  },
});
