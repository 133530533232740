import {
  PageContainer as BasePageContainer,
  styled,
} from '@cherre-frontend/ui';

export const PageContainer = styled(BasePageContainer)`
  padding: 0;
  flex-direction: row;
  flex: 1;
`;

export const EmbedContainer = styled('div')`
  display: flex;
  flex: 1;

  > div {
    flex: 1;
  }
`;

export const Info = styled('p')`
  align-self: center;
  width: 100%;
  text-align: center;
`;
