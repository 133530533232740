import { MuiAutocomplete, styled } from '@cherre-frontend/ui';
import { pageTitleColor } from 'src/const/theme';

export const Autocomplete = styled(MuiAutocomplete)`
  width: 220px;

  svg {
    color: ${({ theme }) => theme.palette.primary.light};
  }

  .MuiFormControl-root .MuiOutlinedInput-root:hover fieldset,
  fieldset {
    border-color: ${({ theme }) => theme.palette.primary.light};
  }

  input,
  label {
    color: ${pageTitleColor};
  }
` as typeof MuiAutocomplete;

export const Wrapper = styled('span')<{ suspended: boolean }>`
  display: flex;
  gap: 12px;
  position: relative;

  .MuiAutocomplete-popper {
    width: fit-content !important;
    max-width: 380px !important;
    inset: 37px 0 0 0 !important;
    transform: unset !important;

    li {
      display: flex;
      justify-content: space-between;

      &:hover .starOutline {
        opacity: 1;
      }

      .starOutline {
        opacity: 0;
      }

      span {
        flex: auto;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      * + span {
        padding-left: 10px;
      }
    }
  }

  ${({ suspended }) =>
    suspended
      ? `
      height: 100%;
      width: 220px;
      opacity: 0.2;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%
      ),
      lightgray;
      background-size: 100vh 100vw;
      animation: shine 1s infinite;
      * {
        display: none;
      }
  `
      : ''}
`;
